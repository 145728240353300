/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUserProfiles = /* GraphQL */ `query GetUserProfiles($id: ID!) {
  getUserProfiles(id: $id) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserProfilesQueryVariables,
  APITypes.GetUserProfilesQuery
>;
export const listUserProfiles = /* GraphQL */ `query ListUserProfiles(
  $filter: TableUserProfilesFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      emailAddress
      userFirstName
      userMiddleName
      userLastName
      userScreenName
      heightFeet
      heightInches
      age
      gender
      targetGender
      zipCode
      phoneNumber
      profilePicture
      biography
      biographyPictures
      profilePictureNames
      preferences
      personalInfo
      locationRadius
      minAge
      maxAge
      userType
      resultsYes
      resultsNo
      resultsMaybe
      userMatches
      incomingSwimmers
      id
      profileComplete
      imageActivityPreference
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserProfilesQueryVariables,
  APITypes.ListUserProfilesQuery
>;
export const getLSMActivitiesTable = /* GraphQL */ `query GetLSMActivitiesTable($id: String!) {
  getLSMActivitiesTable(id: $id) {
    id
    activityIndex
    activityName
    activityCategory
    activityDescription
    activityEnvironment
    activityLabel
    activityTitle
    activityType
    activityUrl1
    activityUrl2
    activityUrl3
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLSMActivitiesTableQueryVariables,
  APITypes.GetLSMActivitiesTableQuery
>;
export const getLSMPartnersTable = /* GraphQL */ `query GetLSMPartnersTable($id: String!) {
  getLSMPartnersTable(id: $id) {
    id
    activiityCategories
    activityCategory
    associatedPartners
    content
    email
    headline
    image
    name
    phone
    reservationInfo
    website
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLSMPartnersTableQueryVariables,
  APITypes.GetLSMPartnersTableQuery
>;
export const listLSMActivitiesTables = /* GraphQL */ `query ListLSMActivitiesTables(
  $filter: TableLSMActivitiesTableFilterInput
  $limit: Int
  $nextToken: String
) {
  listLSMActivitiesTables(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      activityIndex
      activityName
      activityCategory
      activityDescription
      activityEnvironment
      activityLabel
      activityTitle
      activityType
      activityUrl1
      activityUrl2
      activityUrl3
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLSMActivitiesTablesQueryVariables,
  APITypes.ListLSMActivitiesTablesQuery
>;
export const listLSMPartnersTables = /* GraphQL */ `query ListLSMPartnersTables(
  $filter: TableLSMPartnersTableFilterInput
  $limit: Int
  $nextToken: String
) {
  listLSMPartnersTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      activiityCategories
      activityCategory
      associatedPartners
      content
      email
      headline
      image
      name
      phone
      reservationInfo
      website
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLSMPartnersTablesQueryVariables,
  APITypes.ListLSMPartnersTablesQuery
>;
