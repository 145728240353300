import { LitElement, html } from 'lit';
import { customElement,property } from 'lit/decorators.js';
import { resolveRouterPath,router, setActivityFilter ,setActivityNames } from '../../router';

import { Amplify } from 'aws-amplify';
import config from '../../amplifyconfiguration.json';
import { generateClient } from 'aws-amplify/api';
import { listLSMActivitiesTables  } from '../../graphql/queries';
import '../../components/activities-header.js';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

//import * as mutations from '../../graphql/mutations';

// You can also import styles from another file
// if you prefer to keep your CSS seperate from your component
import { styles } from './activities-styles';

import { styles as sharedStyles } from '../../styles/shared-styles'
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/details/details.js';
import '@shoelace-style/shoelace/dist/components/checkbox/checkbox.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js';
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import SlDialog from '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
//import { data } from '@thepassle/app-tools/router/plugins/data.js';




@customElement('app-activities')
export class AppActivities extends LitElement {
  static styles = [
    sharedStyles,
    styles
  ]

  constructor() {
    super();
   // alert("LetsSea connects you in a way that also helps the businesses in the communities that you live in.");
    //get the list of activities defined in the database
    this.callAWS();
    //get any activities this user might have previouisly selected.
    let userEmailAddress = localStorage.getItem("emailAddress") as string;
    this.fetchUserProfileInfo(userEmailAddress); //TODO do we need this call, since we are calling aws again on the next line.
    this.getSelectedImagesfromAWS()
    setActivityFilter('all'); //this is defined in the router.
  }

  firstUpdated() {

  }
  connectedCallback() {
    super.connectedCallback()

}


  @property({type: Boolean})  refreshProfileImages = false;
  @property({type: Array}) profileImageArray = [''];
  @property({type: Array}) userNameArray = [''];
  @property({type: Array}) serverProfiles = ['']; //list of activities
  @property({ type: Array }) profileImageArrayMain:string [] = [];
  @property() databaseID = "databaseID";
  @property({type : String}) temp='';
  @property({type : String}) seletedActivityFilter='all';
  @property({type:String}) userProfileAge='';

  @property ({type:Array}) activityImage:string [] =[];
  selectedActivities:number[] = [];
  updateName(e: CustomEvent) {

    if (e.detail != null) {

    this.seletedActivityFilter = e.detail.value;
    this.callAWS();
    }
  }


  resultCardTemplate(profile:string){



      const a = JSON.stringify(profile)

      var parsedData = JSON.parse(a);

      this.databaseID = parsedData.id;
     // let selectedCssClass = "checkMark"
    // let selectedCssClass = "borderMark"
     let selectedCssClass = "avatar-button";

      if (this.selectedActivities.includes(parseInt(this.databaseID)) ){
          //user has selected this image before, so we need to add the  .avator-buttonSelected css class to it.
          console.log("adding css class");
         // selectedCssClass = "checkMarkSelected";
         // selectedCssClass = "borderMarkSelected";
         selectedCssClass = "avatar-buttonSelected";
      }



      return html `

      <sl-card class="resultscard" >




      <button @click=${() => this._imageClicked(parsedData.id,parsedData.profilePicture, parsedData.userName)} style="background-color: white;
      border: none;" type="button" class=${selectedCssClass} id="activitySelect_${parsedData.id}">
         <sl-avatar  class="avatar"  shape="rounded" image="${parsedData.activityUrl1}" label="User avatar">
         </sl-avatar>
      </button>

      <sl-dialog label="Dialog" class="dialog-scrolling">
        <div style="height: 150vh; border: dashed 2px var(--sl-color-neutral-200); padding: 0 1rem;">
          <p>Advertisers info goes here</p>
        </div>
        <sl-button slot="footer" variant="primary">Close</sl-button>
      </sl-dialog>

    <!-- replace w/ a sldialog?  <sl-button @click=${() => this._infoClicked(parsedData.id, parsedData.activityCategory)} class="partnersButton" size="small" > -->
      <div class="partnersDiv">
      <sl-button @click=${() => this._openDialog()} class="partnersButton" size="small" >

      <div style="color: black;">
      <sl-icon name="geo-alt-fill" label="Yes"></sl-icon>
      </div>
      </sl-button>
      </partnersDiv>
      <div class="yesbuttontext">
      </div>

        <!--
        <div class="result">
            <sl-button @click=${() => this._yesClicked(parsedData.id)} class="yesbutton" >
            </sl-button>


            <sl-icon-button name="check-circle" @click=${() => this._yesClicked(parsedData.id)} class="yesActButton" >
            </sl-icon-button>
        </div>
        -->
        <!--
        <div id="checkMark_${parsedData.id}" class=${selectedCssClass} >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
          </svg>
        </div>
        -->
        <!--
        <div id="borderMark_${parsedData.id}" class=${selectedCssClass} >


        </div>
        -->









        <div class=activityTitle>${parsedData.activityTitle}</div>
        <!--
        <p>activityName: ${parsedData.activityName}</p>
        <p>activity Description: ${parsedData.activityDescription}</p>
        <p>activityCategory: ${parsedData.activityCategory}<p>
        <p>activityEnvironment: ${parsedData.activityEnvironment}<p>
        <p>activityType: ${parsedData.activityType}<p>
        <p>activityLabel: ${parsedData.activityLabel}<p>
        -->
    </sl-card>
    `;

  }
  render() {


    return html`
      <main>
      <section>
      <div class="pageTitle">
        Date Ideas
      </div>
     </section>
      <activities-header  @my-event="${this.updateName}" value="${this.seletedActivityFilter}">  </activities-header>




        <h2>Let's Sea Date Ideas</h2>
        <form-input
        @my-event="${this.updateName}"
        fieldName="name"
        id="name"
        label="Name"
        placeholder="Enter anything"
        value="${this.seletedActivityFilter}"
        ></form-input>
        <!-- testing out removing this text and the button to move to the partners page
        <sl-alert variant="neutral" open closable class="alert-closable">
        <sl-icon slot="icon" name="info-circle"></sl-icon>
        Want to support local businesses on your date? Check out Partners.
        </sl-alert>
        <sl-button @click=${() => this._doneClicked()} class="doneButton" > Partners
        </sl-button>
        -->


      <!--   <div id="resultsBar"> -->

         <div class="resultsCardContainer">
              ${this.serverProfiles.map((i) =>this.resultCardTemplate(i) )

              }

          </div>
          <app-header ?enableBack="${true}"></app-header>
         /*
          ${ html` */
        <!--  <ul>${this.profileImageArray.map(i => html`<li>${i}</li>`)}</ul>-->
        `
        }
        <!--
        <sl-button @click=${this._profileButtonClicked} class="floatingMenu">All Done? Set up your profile</sl-button>
        -->

       <!-- </div> -->
     </main>

    `;
  }

  _profileButtonClicked(){
    const routePath=resolveRouterPath('profile');
    router.navigate(routePath);
  }


_openDialog(){
    const dialog = this.shadowRoot?.querySelector('.dialog-scrolling') as SlDialog;
    dialog.show();
   // const openButton = dialog.nextElementSibling;
    const closeButton = dialog!.querySelector('sl-button[slot="footer"]');
    closeButton!.addEventListener('click', () => dialog!.hide());
}

  async _infoClicked(dbid:string, activityCategory:string){
    //in this case, the dbid is the id of the activty clicked.
    //we need to pass this ID to the 'dates' route, so that the data page shows the partners for that date.

    console.log(dbid);

    const routePath=resolveRouterPath('dates');

    setActivityFilter(activityCategory); //this is defined in the router.
    router.navigate(routePath);
  }

  /*
  _imageClicked(dbid:string,profileUrl:string, profileUserName:string){
  //  debugger;
    console.log("app-results---in _imageClicked");
    console.log('dbid:',dbid);
    console.log(profileUrl);
    console.log(profileUserName);
    let imageToAccentId = "#borderMark_"+dbid;
    const accentDiv = this.shadowRoot!.querySelector(imageToAccentId) as HTMLDivElement;



    if (accentDiv.className == 'borderMarkSelected'){
      console.log("this image is already selcted- removing the class")
      accentDiv.classList.remove('borderMarkSelected');
      accentDiv.classList.add('borderMark')
      const index= this.selectedActivities.indexOf(parseInt(dbid));
      this.selectedActivities.splice(index,1)
    }
    else{
      console.log("selecting image--adding css class");
    accentDiv.classList.remove('borderMark');
    accentDiv.classList.add('borderMarkSelected');
    this.selectedActivities.push(parseInt(dbid));
    }

    console.log("selectedActivities array=",this.selectedActivities);
    //and save the activities array back to the DynamoDB
    this.updateSelectedImagesInAWS();

  }
  */

  _imageClicked(dbid:string,profileUrl:string, profileUserName:string){
    console.log("app-results---in _imageClicked");
    console.log('dbid:',dbid);
    console.log(profileUrl);
    console.log(profileUserName);
    let imageToAccentId = "#activitySelect_"+dbid;
    const accentDiv = this.shadowRoot!.querySelector(imageToAccentId) as HTMLButtonElement;
    if (accentDiv.classList.contains('avatar-buttonSelected')){
      console.log("this image is already selcted- removing the class")
      accentDiv.classList.remove('avatar-buttonSelected');
      const index= this.selectedActivities.indexOf(parseInt(dbid));
      this.selectedActivities.splice(index,1)
    }
    else{
    accentDiv.classList.add('avatar-buttonSelected');
    this.selectedActivities.push(parseInt(dbid));
    }
    console.log("selectedActivities array=",this.selectedActivities);
    //and save the activities array back to the DynamoDB
    this.updateSelectedImagesInAWS();

  }



async getSelectedImagesfromAWS(){
    const client = generateClient();
    const variables = {
    filter:{
       id:{
          eq: localStorage.getItem('DynamoDB_ID') as string
       }
   }

  };
  const result = await client.graphql({
     query: queries.listUserProfiles,
     variables: variables
  });
  console.log("Previously selected activites",result);
  let tSelectedActivities = (result.data.listUserProfiles!.items![0]?.imageActivityPreference);
  if (tSelectedActivities!.length >0){
    this.selectedActivities = tSelectedActivities!.map(Number)
 }
  console.log("previously selected images", this.selectedActivities)
  this.requestUpdate();

  }
  async updateSelectedImagesInAWS(){

    //selected activities is the array of ids that correspond to the imgages that the user clicked.
    Amplify.configure(config);
    const client = generateClient();
    const addImageActivtyPreferenceInput =
    {
      id: localStorage.getItem('DynamoDB_ID') as string,

      imageActivityPreference: this.selectedActivities.map(String)
    }
    console.log("calling api");
    const result = await client.graphql({
      query: mutations.updateUserProfiles,
       variables: { input: addImageActivtyPreferenceInput }
      });


    console.log(result.data);

  }

//user clicked no, so emove this person from the list
  _removeProfile(dbid:string){
     //remove this profile from the array of profiles.
     //TODO: there must be a better way to remove an object from the serverProfiles array than the way I am doing in here :)
     for(let i = 0; i<this.serverProfiles.length; i++){
      let p = this.serverProfiles[i];
      p = JSON.stringify(p);
      if (p.includes(dbid)){
        this.serverProfiles.splice(i, 1);
      }
    }

    //TODO: need to make a database call here to make sure that this dbid does not appear again for this user.
    this.requestUpdate();

  }


  _doneClicked(){
    const routePath=resolveRouterPath('dates');
    router.navigate(routePath);

  }

  _noClicked(dbid:string){
    console.log(dbid);
    //display the dialog to make sure that the user really wants to remove this person from their list.
    /*
    let dialogId= '#confirmDialog_'+dbid;
    console.log(dialogId);
    //const dialog1 = this.shadowRoot!.querySelector("[data-name=" + CSS.escape(dialogId)+"]") as HTMLDialogElement
    const dialog1 = this.shadowRoot!.querySelector(dialogId) as HTMLDialogElement

    dialog1.show();

    const yesButton = dialog1.querySelector("#confirmBtn") as HTMLButtonElement;
    yesButton!.addEventListener("click", (event) => {
      event.preventDefault(); // We don't want to submit this fake form
      dialog1.close();
      this._removeProfile(dbid);
    });
    */

  }
//TODO: what happens when a user clicks Yes on an image?
//TODO: we are going to need a new LIST or SET attribute in the UserProfiles Table for this.
 async _yesClicked(dbid:string){

    console.log("Clicked Yes on:",dbid);

    /*
    Amplify.configure(config);

    const client = generateClient();
    const userProfileDetails =
    {
        id: localStorage.getItem('DynamoDB_ID') as string,
        resultsYes: dbid
    }
    console.log("calling api");
    const result = await client.graphql({
       query: mutations.addMatch,
       variables: { input: userProfileDetails }
      });
      console.log("app-results",result);
      */
  }



  //TODO: in the function below add error handling since dataFromAPI could be null/undefined. I have gotten around this for now
  //by using the ! operator to ingore the 'possibly undefined' typescript compiler error.
  async callAWS(){

    console.log("Calling AWS Dynamo for activities");
    Amplify.configure(config);
    const client = generateClient();

    var variables: any
    if (this.seletedActivityFilter == "all"){
      variables = {

      };

    }
    else{
    variables = {
      filter:{
        activityCategory:{
          eq: this.seletedActivityFilter
        }
      }

    };
  }




    const resultAct = await client.graphql({ query: listLSMActivitiesTables, variables:variables });

    const dataFromAPI = resultAct.data.listLSMActivitiesTables!.items;
    console.log("activites table results", dataFromAPI);


    let numberofProfilesReturned=dataFromAPI?.length;
    console.log("numberOfProfilesReturned from Dynamo", numberofProfilesReturned);
    for (let i=0; i< numberofProfilesReturned!;i++){
     // var emailAddress = dataFromAPI![i]!.emailAddress;
      var activityImage = dataFromAPI![i]?.activityUrl1;

      this.getProfileImageURL(activityImage!,i).then(res =>{
        dataFromAPI![i]!.activityUrl1 = res
        this.serverProfiles = JSON.parse(JSON.stringify(dataFromAPI));
        setActivityNames(JSON.parse(JSON.stringify(dataFromAPI)));


      })


    }

    this.refreshProfileImages = true;

   this.requestUpdate();



  }

  private async getProfileImageURL(imageKey:string, i:number){


    // now download the same file from AWS S3 and set it as the profile image preview on this page:
    let key:string = imageKey;

    try{
         //  const downloadResult= await downloadData({ key, options: {
           //} }).result;
           // blob1 = await downloadResult.body.blob();
           //console.log('Success, downloaded profile image from s3 ', key);
           //console.log("downloaded body",blob1);

           let profileURL = imageKey;

          this.temp = profileURL;
          this.profileImageArrayMain.splice(i,0,this.temp);
         //  var imgPreview= this.shadowRoot!.getElementById('profile-image');
          // imgPreview?.setAttribute("image",URL.createObjectURL(blob1));
    } catch (error){

      console.log('Error downloading from S3', key);
      //insert dummy element
      this.profileImageArrayMain.splice(i,0,key);
    }


    return imageKey;






}


async fetchUserProfileInfo(emailAddress:string){

 const client = generateClient();
 const variables = {
   filter:{
     emailAddress:{
       eq: emailAddress
     }
   }

 };
 const result = await client.graphql({
      query: queries.listUserProfiles,
      variables: variables
 });
 console.log(result);
  let responseDynmoDB_ID = (result.data.listUserProfiles!.items![0]?.id);

  localStorage.setItem("DynamoDB_ID", responseDynmoDB_ID!);
 }



}
