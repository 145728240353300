import { css } from 'lit';

// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const styles = css`
  @media(min-width: 5000px) {
    sl-card {
      max-width: 70vw;
    }
  }

  main {
    margin-top: 7.345vh;
    height: 100%;
    margin: 0;
  }
`;