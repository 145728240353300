import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/drawer/drawer.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';
import '@shoelace-style/shoelace/dist/components/switch/switch.js';
import 'https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.12.0/cdn/components/animation/animation.js';
import { SlButton } from '@shoelace-style/shoelace';



@customElement('activities-header')
export class ActivitiesHeader extends LitElement {
  @property({ type: String }) title = 'letssea.me';
  @property({type: Boolean}) menuOpen: boolean = false;
  @property({ type: Boolean}) enableBack: boolean = false;
  @property({ type: Boolean}) loggedIn = false;
  @property({type : String}) seletedActivityFilter='nature';

  constructor(){
    super();

  }


  async firstUpdated() {
  }

  async updated(){
    var filter= localStorage.getItem('activityFilter');

    if (!filter){
      filter = "fooddrink"; //make this our default
    }
    this._preClick(filter);
  }
  static styles = css`

  activities-header {
    background-color: red;
  }
    header {
      z-index: 99;
      width:100%;
      display: flex;
      flex-direction:row;
      justify-content: space-between;
      align-items: center;
     /* background: var(--app-color-primary);*/
     background: 	white;
      color: white;
      height: 4em;
      padding-left: 5px;
      padding-top: 0px;


      position: fixed;
      left: env(titlebar-area-x, 0);
      top: env(titlebar-area-y, 0);


      height: env(titlebar-area-height, 90px);

      width: env(titlebar-area-width, 100%);
      -webkit-app-region: drag;
      scroll-behavior: smooth;
    }

    header h1 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: bold;
    }

    nav a {
      margin-left: 10px;
    }


    .nav-brand{
      margin-right:5px;
    }
    @media(prefers-color-scheme: light) {
      header {
       /* color: black;*/
      }

      nav a {
        color: initial;
      }
    }

    sl-button::part(base){
      background-color: white;
      border-color: white;
      border-radius: 0;
      text-decoration: underline;
      text-decoration-color: #33B5B4;
      text-decoration-thickness: 3px;
      font-size:20px;

    }
    sl-button::part(label){

      color: gray;
    }

    .filter-button::part(base){


      text-decoration-thickness: 4px;

    }
    .filter-button::part(label){

      color: #33B5B4
    }


    .hidden {
        display: none;
      }

    .filters{
        background-color:white;
        display: flex;
        overflow: auto;
        height: 60px;
        align-items: center;
    }

  `;

  render() {

    return html`




      <div class="filters">
                 <child-element id="cld"></child-element>

                <sl-button  name="all" @click=${() => this._filterClicked("all")} id="all" class="yesbutton" >All
                </sl-button>
                <sl-button name="outdoor" @click=${() => this._filterClicked("outdoor")} id="outdoor" class="yesbutton" >Outdoor
                </sl-button>
                <sl-button name="cultural" @click=${() => this._filterClicked("cultural")} id="cultural" class="yesbutton" >Arts & Culture
                </sl-button>
                <sl-button name="fooddrink" @click=${() => this._filterClicked("fooddrink")} id="fooddrink" class="yesbutton" >Food & Drink
                </sl-button>
                <sl-button name="learning" @click=${() => this._filterClicked("learning")} class="yesbutton" id="learning" >Learning
                </sl-button>
                <sl-button name="leisure" @click=${() => this._filterClicked("leisure")} id="leisure" class="yesbutton" >Leisure & Relaxation
                </sl-button>
                <sl-button @click=${() => this._filterClicked("physical")} id="physical" class="yesbutton" >Sports & Physical Activities
                </sl-button>
                <sl-button name="unusual" @click=${() => this._filterClicked("unusual")} id="unusual" class="yesbutton" >Unusual & Quirky
                </sl-button>
                <sl-button @click=${() => this._filterClicked("eduhist")} id = "eduhist" class="yesbutton" >Educational and Historical
                </sl-button>
                <sl-button name="nature" @click=${() => this._filterClicked("nature")} id="nature" class="yesbutton" >Nature and Wildlife
                </sl-button>
                <sl-button name="entertain" @click=${() => this._filterClicked("entertain")} id="entertain" class="yesbutton" >Entertainment & Nightlife
                </sl-button>

                <!--
                <input
                @input=${this._filterClicked}
                id="${this.seletedActivityFilter}"
                />
                -->

     </div>


    `;
  }


  dispatchInput(e: CustomEvent) {
    const target = e.target as HTMLInputElement;
    this.dispatchEvent(new CustomEvent("new-value", {
      bubbles: true,
      detail: target.value}));
   }


  async _preClick(filterType:string){

   await this.updateComplete;
  let menuItem = '#'+filterType;
  const tButton = <SlButton>this.shadowRoot!.querySelector(menuItem)as SlButton;
  tButton.scrollIntoView({ behavior: "smooth", block: "end", inline: "start" });
  tButton!.click();

}


  _filterClicked(filterType:string){
    //turn off the highlights for all of the buttons in the header
    let buttons = Array.from(this.shadowRoot!.querySelectorAll(".yesbutton"));
    buttons.forEach(button => {
      button.classList.remove('filter-button');
    });


    console.log("act filters clicked",filterType);
    let menuItem = '#'+filterType;
    const tButton = <SlButton>this.shadowRoot!.querySelector(menuItem)as SlButton;
    tButton.classList.add('filter-button');
    const myevent = new CustomEvent('my-event', {
        bubbles: true,
        composed: true,
        detail: {
          value: filterType
        }
    })
        this.dispatchEvent(
            myevent
        );




  }


}
